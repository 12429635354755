.item {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: -5px;
  overflow: hidden;
  padding: 20px 28px 17px;
  position: relative;
  width: 600px;
}

.wrap {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 0px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.item-content {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  
}

.item-pic {
  background-image: url(/public/img/image-21.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 128.5px;
  position: relative;
  width: 137px;
}

.item-title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width:300px;

}

.title {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.2px;
  margin-top: -1px;
  opacity: 0.9;
  position: relative;
  white-space: wrap;
  overflow-wrap: break-word;

  
}

.item .description {
  color: #666666;
  font-family: "Cardo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.5px;
  opacity: 0.9;
  position: relative;
  /* width: fit-content; */
  width: 300px;
}

.frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;

  justify-content: space-between;
  position: relative;
}

.element {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 36px;
  margin-top: -1px;
}

.span {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 36px;
}

.text-wrapper-3 {
  font-weight: 700;
}

.group {
  height: 28px;
  margin-right: -2px;
  opacity: 0.2;
  position: relative;
  width: 83px;
}

.overlap-group {
  height: 28px;
  position: relative;
  width: 81px;
}

.item-p {
  
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 25px;
  font-weight: 400;
  margin-top: 0;  
  opacity: 0.7;
}

.text-wrapper-4 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.2px;
}

.rectangle {
  background-color: #1a4e9a;
  height: 2px;
  left: 0;
  opacity: 0.78;
  position: absolute;
  top: 15px;
  width: 81px;
}

@media only screen and (max-width:600px) {
  .item {
    align-items: center;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* height: 156px; */
    height: unset !important;
    justify-content: center;
    margin-right: -5px;
    overflow: hidden;
    padding: 0px 0px 17px;
    padding-left: 10px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  
  .item .wrap {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    
    justify-content: space-between;
    margin-bottom: -4.75px;
    margin-left: -21.5px;
    margin-right: -21.5px;
    margin-top: -4.75px;
    position: relative;
    /* width: 100%; */
    width: fit-content;
    overflow-x: scroll;
  }
  
  .item-content {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
  }
  
  .item-pic {
    display: none !important;
    background-image: url(/public/img/image-21.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 128.5px;
    position: relative;
    width: 137px;
  }
  
  .item-title {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 5px;
    width: 220px;
  }
  
  .item-title .title {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28.2px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    padding-top: 20px;
  }
  
  .item .description {
    color: #666666;
    font-family: "Cardo", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20.5px;
    opacity: 0.9;
    position: relative;
    width: fit-content;
  }
  
  .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
    position: relative;
  }
  
  .element {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
  }
  
  .span {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
  }
  
  .text-wrapper-3 {
    font-weight: 700;
  }
  
  .group {
    height: 28px;
    margin-right: -2px;
    opacity: 0.2;
    position: relative;
    width: 83px;
  }
  
  .overlap-group {
    height: 28px;
    position: relative;
    width: 81px;
  }
  
  .item-p {
    display: unset !important;
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28.2px;
    opacity: 0.5;
  }
  
  .text-wrapper-4 {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28.2px;
  }
  
  .rectangle {
    background-color: #1a4e9a;
    height: 2px;
    left: 0;
    opacity: 0.78;
    position: absolute;
    top: 15px;
    width: 81px;
  }
}