.recommendations {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 36px 36px;
  position: relative;
  width: 100%;
  padding-bottom: 30px;
}
.reco-item {
  box-sizing: border-box;
  height: 250px;
  align-items: center;
  background-color: #f1fafe;
  border-radius: 20.58px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
  width: "100%";
}

.img {
  height: 124px;
  position: relative;
  width: 108px;
  
}

.image {
  height: 123px;
  left: 0;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 102px;

}

.NFO-sample-papers {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23.1px;
  opacity: 0.9;
  position: relative;
  text-align: center;
  width: fit-content;
}

.reco-row-align {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.product-info {
  height: 34px;
  position: relative;
  width: 42px;
  border: none;
  background: none;
  margin-right: 10px;
  cursor: pointer;
}

.overlap-group-2 {
  border-radius: 11.69px;
  height: 34px;
  position: relative;
}

.rectangle-2 {
  background-color: #023b8f;
  border: 0.56px solid;
  border-color: #8a8a8a;
  border-radius: 11.69px;
  height: 34px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 42px;
}

.eye {
  height: 19px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: 19px;
}

.text-wrapper-6 {
  -webkit-text-stroke: 0.4px #023b8f;
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.additembutton {
  height: 34px;
  margin-right: -2px;
  position: relative;
  width: 116px;
}

.overlap {
  border: none;
  border-radius: 11.69px;
  height: 34px;
  position: relative;
  width: 114px;
  cursor: pointer;
  background-color: rgba(2, 59, 143, 0.1);
}

.rectangle-3 {
  background-color: #023b8f;
  border: 0.56px solid;
  border-color: #8a8a8a;
  border-radius: 11.69px;
  height: 34px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 114px;
}

.text-wrapper-7 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 76px;
}

@media only screen and (max-width: 430px) {

  .reco-item {
    box-sizing: border-box;
    height: 250px;
  }

}